(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-ui-react/widget-area/assets/widget-area.js') >= 0) return;  svs.modules.push('/components/tb-ui-react/widget-area/assets/widget-area.js');

'use strict';

const {
  useEffect,
  useState
} = React;
const widget_area = svs.core.widget_area;
const WidgetArea = _ref => {
  let {
    areaName,
    className,
    fallback = null
  } = _ref;
  const [content, setContent] = useState();
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    widget_area.loadArea(areaName, content => {
      setContent(content);
      setIsReady(true);
    });
  }, [areaName]);
  if (content) {
    return React.createElement("div", {
      className: className,
      dangerouslySetInnerHTML: {
        __html: content
      }
    });
  }

  return isReady && fallback;
};
svs.components.tb_ui_react.widget_area.WidgetArea = WidgetArea;

 })(window);