(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-ui-react/widget-area/assets/prescript.js') >= 0) return;  svs.modules.push('/components/tb-ui-react/widget-area/assets/prescript.js');

'use strict';

svs.components = svs.components || {};
svs.components.tb_ui_react = svs.components.tb_ui_react || {};
svs.components.tb_ui_react.widget_area = svs.components.tb_ui_react.widget_area || {};
svs.components.tb_ui_react.widget_area.logger = new svs.core.log.Logger('component:react-widget-area');



 })(window);